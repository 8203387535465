.navbar {
    position: fixed;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: calc(100vw - 20px);
    height: 40px;
    padding: 10px;

    background-color: #04395e;
    color: #fff;

    font-size: 15px;
}

.login {
    width: 300px;

    border-radius: 10px;
    border: 3px solid #1976d2;

    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    padding: 20px;

    margin-top: 300px;
    margin-left: auto;
    margin-right: auto;
}

.section {
    margin: 30px auto;

    border-radius: 10px;
    border: 3px solid #1976d2;

    padding: 20px;
}

.imageContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.labelImage {
    max-width: 100%;
}

.labellingSection {
    display: flex !important;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.option {
    display: flex;
    flex-direction: row;

    align-items: flex-start;
    justify-content: center;

    margin-top: 10px;
}

.mistakeSection {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.footer {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

}

.main {
    position: relative;

    min-height: 100vh;
}